export default class AccessibilityNav {
  constructor(domNode, breakpoint) {

    let msgPrefix = 'Menubar constructor argument menubarNode ';

    // Check whether menubarNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }

    // Check whether menubarNode has descendant elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }

    // Check whether menubarNode has A elements
    let e = domNode.firstElementChild;

    while (e) {
      let menubarItem = e.firstElementChild;
      if (e && menubarItem && menubarItem.tagName !== 'A') {
        throw new Error(msgPrefix + 'has child elements are not A elements.');
      }
      e = e.nextElementSibling;
    }

    this.isMenubar = true;

    this.domNode = domNode;

    this.breakpoint = breakpoint || '768px';

    this.menubarItems = []; // See Menubar init method
    this.firstChars = []; // See Menubar init method

    this.firstItem = null; // See Menubar init method
    this.lastItem = null; // See Menubar init method

    this.hasFocus = false; // See MenubarItem handleFocus, handleBlur
    this.hasHover = false; // See Menubar handleMouseover, handleMouseout

    this.init();
  }

  init() {
    let menubarItem, childElement, menuElement, textContent, numItems;

    // Traverse the element children of menubarNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    let elem = this.domNode.firstElementChild;

    while (elem) {
      menuElement = elem.firstElementChild;

      if (elem && menuElement && menuElement.tagName === 'A') {
        menubarItem = new MenubarItem(menuElement, this);
        menubarItem.init();
        this.menubarItems.push(menubarItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }

      elem = elem.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menubarItems.length;

    if (numItems > 0) {
      this.firstItem = this.menubarItems[0];
      this.lastItem = this.menubarItems[numItems - 1];
    }
  }

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        return i;
      }
    }
    return -1;
  }
}

// Menubar Item Module
class MenubarItem {
  constructor(domNode, menuObj) {
    this.menu = menuObj;
    this.domNode = domNode;
    this.popupMenu = false;

    this.hasFocus = false;
    this.hasHover = false;

    this.isMenubarItem = true;
  }

  init() {
    this.keyDownHandler = this.handleKeydown.bind(this);
    this.focusHandler = this.handleFocus.bind(this);
    this.blurHandler = this.handleBlur.bind(this);
    this.mouseoverHandler = this.handleMouseover.bind(this);
    this.mouseoutHandler = this.handleMouseout.bind(this);
    this.touchHandler = this.handleTouch.bind(this);
    let nextElement = this.getDropdown(this.domNode);

    if (nextElement) {
      this.popupMenu = new PopupMenu(nextElement, this);
      this.popupMenu.init();
    }

    const mediaQuery = window.matchMedia(`(min-width: ${this.menu.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        // attach events for aa items
        this.domNode.addEventListener('keydown', this.keyDownHandler);
        this.domNode.addEventListener('focus', this.focusHandler);
        this.domNode.addEventListener('blur', this.blurHandler);

        // set tabindex value
        // this.domNode.tabIndex = -1;

        if (nextElement) {
          // attach events for openers
          this.domNode.addEventListener('mouseover', this.mouseoverHandler);
          this.domNode.addEventListener('mouseout', this.mouseoutHandler);
          this.domNode.addEventListener('touchend', this.touchHandler);

          // set aria attributes
          this.domNode.setAttribute('aria-haspopup', 'true');
          this.domNode.setAttribute('aria-expanded', 'false');
        }
      } else {
        // remove events listeners from all items
        this.domNode.removeEventListener('keydown', this.keyDownHandler);
        this.domNode.removeEventListener('focus', this.focusHandler);
        this.domNode.removeEventListener('blur', this.blurHandler);

        // clear tabindex value
        this.domNode.tabIndex = '';

        if (nextElement) {
          // remove events listeners from openers
          this.domNode.removeEventListener('mouseover', this.mouseoverHandler);
          this.domNode.removeEventListener('mouseout', this.mouseoutHandler);
          this.domNode.removeEventListener('touchend', this.touchHandler);

          // remove aria attributes
          this.domNode.removeAttribute('aria-haspopup');
          this.domNode.removeAttribute('aria-expanded');
        }
      }
    }
  }

  getDropdown(el) {
    let nextElement = el.nextElementSibling;

    while (nextElement) {
      if (nextElement.tagName === 'UL') return nextElement;
      if (nextElement.tagName === 'DIV') return nextElement;
      nextElement = nextElement.nextElementSibling;
    }
  }

  handleKeydown(event) {
    let char = event.key,
      flag = false;
    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.key) {
      //case " ":
      //case "Enter":
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        if (this.popupMenu) {
          this.popupMenu.open();
          flag = true;
        }
        break;

      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        flag = true;
        break;

      case "Right": // IE/Edge specific value
      case "ArrowRight":
        flag = true;
        break;

      case "Up": // IE/Edge specific value
      case "ArrowUp":
        if (this.popupMenu) {
          this.popupMenu.open();
          flag = true;
        }
        break;

      case "Home":
      case "PageUp":
        flag = true;
        break;

      case "End":
      case "PageDown":
        flag = true;
        break;

      // case "Tab":
      //   if (this.popupMenu) this.popupMenu.close(true);
      //   break;

      case "Esc": // IE/Edge specific value
      case "Escape":
        this.popupMenu.close(true);
        break;

      default:
        if (isPrintableCharacter(char)) {
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setExpanded(value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true');
    } else {
      this.domNode.setAttribute('aria-expanded', 'false');
    }
  }

  handleFocus() {
    this.menu.hasFocus = true;
  }

  handleBlur() {
    this.menu.hasFocus = false;
  }

  handleMouseover() {
    this.hasHover = true;
    this.popupMenu.open();
  }

  handleMouseout() {
    this.hasHover = false;
    setTimeout(this.popupMenu.close.bind(this.popupMenu), 10);
  }

  handleTouch(e) {
    if (this.domNode.getAttribute('aria-expanded') === 'false') {
      e.preventDefault();
    }

    if (this.popupMenu) {
      this.popupMenu.open();
    }
  }
}

// Menu Item Module
class MenuItem {
  constructor(domNode, menuObj, breakpoint) {
    this.domNode = domNode;
    this.menu = menuObj;
    this.popupMenu = false;
    this.isMenubarItem = false;
    this.breakpoint = breakpoint;
  }

  init() {
    this.keyDownHandler = this.handleKeydown.bind(this);
    this.focusHandler = this.handleFocus.bind(this);
    this.blurHandler = this.handleBlur.bind(this);
    this.mouseoverHandler = this.handleMouseover.bind(this);
    this.mouseoutHandler = this.handleMouseout.bind(this);
    this.touchHandler = this.handleTouch.bind(this);

    // Initialize flyout menu
    let nextElement = this.getDropdown(this.domNode);

    if (nextElement) {
      this.popupMenu = new PopupMenu(nextElement, this);
      this.popupMenu.init();
    }

    const mediaQuery = window.matchMedia(`(min-width: ${this.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        this.domNode.addEventListener('keydown', this.keyDownHandler);
        this.domNode.addEventListener('focus', this.focusHandler);
        this.domNode.addEventListener('blur', this.blurHandler);
        this.domNode.addEventListener('mouseover', this.mouseoverHandler);
        this.domNode.addEventListener('mouseout', this.mouseoutHandler);
        this.domNode.addEventListener('touchend', this.touchHandler);

        // set tabindex value
        // this.domNode.tabIndex = -1;

        if (nextElement) {
          this.domNode.setAttribute('aria-haspopup', 'true');
          this.domNode.setAttribute('aria-expanded', 'false');
        }
      } else {
        // remove events listeners
        this.domNode.removeEventListener('keydown', this.keyDownHandler);
        this.domNode.removeEventListener('focus', this.focusHandler);
        this.domNode.removeEventListener('blur', this.blurHandler);
        this.domNode.removeEventListener('mouseover', this.mouseoverHandler);
        this.domNode.removeEventListener('mouseout', this.mouseoutHandler);
        this.domNode.removeEventListener('touchend', this.touchHandler);

        // clear tabindex value
        this.domNode.tabIndex = '';

        if (nextElement) {
          // remove aria attributes
          this.domNode.removeAttribute('aria-haspopup');
          this.domNode.removeAttribute('aria-expanded');
        }
      }
    }
  }

  getDropdown(el) {
    let nextElement = el.nextElementSibling;

    while (nextElement) {
      if (nextElement.tagName === 'UL') return nextElement;
      if (nextElement.tagName === 'DIV') return nextElement;
      nextElement = nextElement.nextElementSibling;
    }
  }

  /* EVENT HANDLERS */
  handleKeydown(event) {
    let char = event.key,
      flag = false;

    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.key) {
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        flag = true;
        break;

      case "Down": // IE/Edge specific value
      case "ArrowDown":
        flag = true;
        break;

      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        this.menu.close(true);
        flag = true;
        break;

      case "Right": // IE/Edge specific value
      case "ArrowRight":
        if (this.popupMenu) {
          this.popupMenu.open();
        } else {
          this.menu.close(true);
        }
        flag = true;
        break;

      case "Home":
      case "PageUp":
        flag = true;
        break;

      case "End":
      case "PageDown":
        flag = true;
        break;

      case "Esc": // IE/Edge specific value
      case "Escape":
        this.menu.close(true);
        flag = true;
        break;

      // case "Tab":
      //   this.menu.setFocusToController();
      //   break;

      default:
        if (isPrintableCharacter(char)) {
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setExpanded(value) {
    if (value) {
      this.domNode.setAttribute('aria-expanded', 'true');
    } else {
      this.domNode.setAttribute('aria-expanded', 'false');
    }
  }

  handleFocus() {
    this.menu.hasFocus = true;
  }

  handleBlur() {
    this.menu.hasFocus = false;
    setTimeout(this.menu.close.bind(this.menu), 10);
  }

  handleMouseover() {
    this.menu.hasHover = true;
    this.menu.open();

    if (this.popupMenu) {
      this.popupMenu.hasHover = true;
      this.popupMenu.open();
    }
  }

  handleMouseout() {
    if (this.popupMenu) {
      this.popupMenu.hasHover = false;
      this.popupMenu.close(true);
    } else {
      this.menu.hasHover = false;
      setTimeout(this.menu.close.bind(this.menu), 10);
    }
  }

  // touch
  handleTouch(e) {
    if (this.domNode.getAttribute('aria-expanded') === 'false') {
      e.preventDefault();
    }

    if (this.popupMenu) {
      this.popupMenu.open();
    }
  }
}

// Popup Menu Module
class PopupMenu {
  constructor(domNode, controllerObj) {
    let msgPrefix = 'PopupMenu constructor argument domNode ';

    // Check whether domNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }
    // Check whether domNode has child elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }
    // Check whether domNode descendant elements have A elements
    let childElement = domNode.firstElementChild;
    while (childElement) {
      let menuitem = childElement.firstElementChild;
      if (menuitem && menuitem === 'A') {
        throw new Error(msgPrefix + 'has descendant elements that are not A elements.');
      }
      childElement = childElement.nextElementSibling;
    }

    this.isMenubar = false;

    this.domNode = domNode;
    this.controller = controllerObj;

    this.menuitems = []; // See PopupMenu init method
    this.firstChars = []; // See PopupMenu init method

    this.firstItem = null; // See PopupMenu init method
    this.lastItem = null; // See PopupMenu init method

    this.hasFocus = false; // See MenuItem handleFocus, handleBlur
    this.hasHover = false; // See PopupMenu handleMouseover, handleMouseout
  }

  init() {
    let childElement, menuElement, menuItem, textContent, numItems;
    const mouseoverHandler = this.handleMouseover.bind(this);
    const mouseoutHandler = this.handleMouseout.bind(this);
    const outsideClickHandler = this.handleOutsideClick.bind(this);
    const domNode = this.domNode;

    const mediaQuery = window.matchMedia(`(min-width: ${this.controller.menu.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange);

    // Initial check
    handleChange(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        domNode.addEventListener('mouseover', mouseoverHandler);
        domNode.addEventListener('mouseout', mouseoutHandler);
        document.addEventListener('click', outsideClickHandler);
      } else {
        domNode.removeEventListener('mouseover', mouseoverHandler);
        domNode.removeEventListener('mouseout', mouseoutHandler);
        document.removeEventListener('click', outsideClickHandler);
      }
    }

    // Traverse the element children of domNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    childElement = this.domNode.tagName === 'DIV' ? this.domNode.querySelector(':scope ul').firstElementChild : this.domNode.firstElementChild;

    while (childElement) {
      menuElement = childElement.firstElementChild;

      if (menuElement && menuElement.tagName === 'A') {
        const breakpoint = this.controller.breakpoint || this.controller.menu.breakpoint || this.controller.menu.controller.menu.breakpoint;

        menuItem = new MenuItem(menuElement, this, breakpoint);
        menuItem.init();
        this.menuitems.push(menuItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }

      childElement = childElement.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menuitems.length;
    if (numItems > 0) {
      this.firstItem = this.menuitems[0];
      this.lastItem = this.menuitems[numItems - 1];
    }
  }

  eventPath(evt) {
    let path = (evt.composedPath && evt.composedPath()) || evt.path,
      target = evt.target;

    if (path != null) {
      // Safari doesn't include Window, but it should.
      return (path.indexOf(window) < 0) ? path.concat(window) : path;
    }

    if (target === window) {
      return [window];
    }

    function getParents(node, memo) {
      memo = memo || [];
      let parentNode = node.parentNode;

      if (!parentNode) {
        return memo;
      } else {
        return getParents(parentNode, memo.concat(parentNode));
      }
    }

    return [target].concat(getParents(target), window);
  }

  handleOutsideClick(event) {
    let path = this.eventPath(event);
    let flag = false;

    path.forEach((el) => {
      let isEqual = el === this.holder;

      if (isEqual) {
        flag = isEqual;

        return false;
      }
    });

    if (!flag) {
      this.hasHover = false;
      setTimeout(this.close.bind(this), 1);

      this.controller.menu.close && this.controller.menu.close(true);
      this.controller.hasFocus = false;
    }
  }

  handleMouseover() {
    this.hasHover = true;
  }

  handleMouseout() {
    this.hasHover = false;
    setTimeout(this.close.bind(this), 10);
  }


  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        return i;
      }
    }
    return -1;
  }

  /* MENU DISPLAY METHODS */

  open() {
    this.domNode.classList.add('accessibility-drop-hover');
    this.domNode.closest('li').classList.add('accessibility-hover');

    this.controller.setExpanded(true);
  }

  close(force) {
    let controllerHasHover = this.controller.hasHover;
    let hasFocus = this.hasFocus;

    for (let i = 0; i < this.menuitems.length; i++) {
      let mi = this.menuitems[i];
      if (mi.popupMenu) {
        hasFocus = hasFocus || mi.popupMenu.hasFocus;
      }
    }

    if (!this.controller.isMenubarItem) {
      controllerHasHover = false;
    }

    if (force || (!hasFocus && !this.hasHover && !controllerHasHover)) {
      const parentItem = this.domNode.closest('li')

      this.domNode.classList.remove('accessibility-drop-hover');
      parentItem.classList.remove('accessibility-hover');
      this.controller.setExpanded(false);

      const stillActive = this.domNode.querySelectorAll('.accessibility-hover');

      if (stillActive.length) {
        stillActive.forEach(item => {
          item.classList.remove('accessibility-hover');
          item.querySelector('.accessibility-drop-hover').classList.remove('accessibility-drop-hover');
          item.querySelector('[aria-expanded="true"]').setAttribute('aria-expanded', 'false');
        });
      }
    }
  }
}
