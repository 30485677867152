export default function initExpandCard() {
  const cards = jQuery('.expanded-card');
  const activeClass = 'active';
  
  if (cards.length === 0) return;

  cards.on('click', function() {
    const current = jQuery(this);

    cards.removeClass(activeClass);
    current.addClass(activeClass);
  })

  cards.eq(0).addClass(activeClass);
}