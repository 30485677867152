export default class BurgerMenu {
  constructor(breakpoint) {
    this.openerSelector = '.menu-opener';
    this.activeClass = 'menu-active';
    this.container = document.body;
    this.dropSelector = '.menu-slide';
    this.breakpoint = breakpoint || '1024px';
    this.focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    this.activated = false;
    this.onShow = new CustomEvent('onShow');
    this.onHide = new CustomEvent('onHide');

    this.init();
  }

  init() {
    this.container.API = this;
    this.opener = document.querySelector(this.openerSelector);
    this.drop = document.querySelector(this.dropSelector);

    const mediaQuery = window.matchMedia(`(min-width: ${this.breakpoint})`);
    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        if (this.activated) this.destroy();
      } else {
        if (this.activated) return;
        this.opener.setAttribute('aria-haspopup', 'true');
        this.opener.setAttribute('aria-expanded', 'false');

        this.drop.setAttribute('z-index', '-1');

        this.attachEvents();
      }
    }
  }

  attachEvents() {
    this.activated = true;
    this.outsideClickHandler = this.outsideClick.bind(this);
    this.keyPressHandler = this.keyPress.bind(this);
    this.clickHandler = handleClick.bind(this);

    this.drop.querySelectorAll(this.focusableElements).forEach(item => {
      item.setAttribute('tabindex', '-1');
    });

    this.opener.addEventListener('click', this.clickHandler);

    function handleClick(e) {
      e.preventDefault();

      if (this.isActive) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
    }
  }

  showMenu() {
    this.container.classList.add(this.activeClass);
    this.isActive = true;
    this.opener.setAttribute('aria-expanded', this.isActive);
    this.drop.removeAttribute('z-index');

    this.drop.querySelectorAll(this.focusableElements).forEach(item => {
      item.removeAttribute('tabindex');
    })

    document.addEventListener('click', this.outsideClickHandler);
    this.container.addEventListener('keydown', this.keyPressHandler);

    // trigger onShow event
    this.container.dispatchEvent(this.onShow);
  }

  hideMenu() {
    this.container.classList.remove(this.activeClass);
    this.isActive = false;
    this.opener.setAttribute('aria-expanded', this.isActive);
    this.drop.setAttribute('z-index', '-1');

    document.removeEventListener('click', this.outsideClickHandler);
    this.container.removeEventListener('keydown', this.keyPressHandler);

    this.drop.querySelectorAll(this.focusableElements).forEach(item => {
      item.setAttribute('tabindex', '-1');
    })

    // trigger onHide event
    this.container.dispatchEvent(this.onShow);
  }

  outsideClick(e) {
    if (!e.target.closest(this.openerSelector) && !e.target.closest(this.dropSelector)) {
      this.hideMenu();
    }
  }

  keyPress(e) {
    const focusableContent = this.drop.querySelectorAll(this.focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];
    let isTabPressed = e.key === 'Tab';

    if (e.key === 'Escape') {
      const curFocused = this.drop.querySelector('[aria-expanded="true"]');

      if (curFocused) return;

      this.hideMenu();
      document.removeEventListener('keydown', this.keyPressHandler);
    }

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        this.opener.focus();
        e.preventDefault();
      } else if (document.activeElement === this.opener) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        this.opener.focus();
        e.preventDefault();
      }
    }
  }

  destroy() {
    this.isActive = '';
    this.container.classList.remove(this.activeClass);
    this.opener.removeAttribute('aria-haspopup');
    this.opener.removeAttribute('aria-expanded');
    this.opener.removeEventListener('click', this.clickHandler);
    document.removeEventListener('click', this.outsideClickHandler);
    this.container.removeEventListener('keyup', this.keyPressHandler);
    this.drop.removeAttribute('z-index');
    this.drop.querySelectorAll(this.focusableElements).forEach(item => {
      item.removeAttribute('tabindex');
    })
    this.container.API = '';
    this.activated = false;
  }
}
