export default class Collapsible {
  constructor({holder, opener, slide, activeClass = 'slide-opened', breakpoint = 0, onShow, onHide}) {
    this.holder = holder;
    this.opener = opener;
    this.slide  = slide;
    this.activeClass = activeClass;
    this.breakpoint = breakpoint;
    this.isOpened = false;
    this.focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    this.onShow = onShow ? onShow : null;
    this.onHide = onHide ? onHide : null;

    this.init();
  }

  events() {
    this.handleClick = this.clickHandler.bind(this);
    this.handleKeyPress = this.keyPressHandler.bind(this);
    this.keyPressHandler = this.keyPress.bind(this);
    this.outsideClickHandler = this.outsideClick.bind(this);
  }

  init() {
    if (typeof this.holder === 'string') {
      this.holder = document.querySelector(this.holder);
    }

    this.slide = this.holder.querySelector(`:scope ${this.slide}`);
    this.opener = this.holder.querySelector(`:scope ${this.opener}`);

    if (this.holder.classList.contains(this.activeClass)) {
      this.showSlide();
    }

    this.events();

    const mediaQuery = window.matchMedia(`(min-width: ${this.breakpoint})`);

    mediaQuery.addEventListener('change', handleChange.bind(this));

    // Initial check
    handleChange.bind(this)(mediaQuery);

    function handleChange(e) {
      if (e.matches) {
        this.destroy();
      } else {
        this.opener.setAttribute('aria-expanded', 'false');
        this.opener.setAttribute('aria-haspopup', 'true');

        this.slide.style.overflow = 'hidden';

        this.opener.addEventListener('click', this.handleClick);
        this.opener.addEventListener('keyup', this.handleKeyPress);
      }
    }
  }

  showSlide() {
    this.holder.classList.add(this.activeClass)
    this.isOpened = true;
    this.opener.setAttribute('aria-expanded', this.isOpened);

    this.slide.addEventListener('transitionend', () => {
      this.slide.style.height = '';
      this.slide.style.display = '';
    }, { once: true });

    this.slide.style.display = 'block';
    this.slide.style.height = '0px';
    this.slide.style.transition = 'height .5s';
    this.slide.style.height = `${this.slide.scrollHeight}px`;

    if (this.onShow) this.onShow();

    this.focusableContent = [...this.slide.querySelectorAll(this.focusableElements)].filter(item => item.getBoundingClientRect().height !== 0);

    document.addEventListener('click', this.outsideClickHandler);
    this.holder.addEventListener('keydown', this.keyPressHandler);
  }

  hideSlide() {
    this.holder.classList.remove(this.activeClass)
    this.isOpened = false;
    this.opener.setAttribute('aria-expanded', this.isOpened);

    this.slide.addEventListener('transitionend', () => {
      this.slide.style.display = 'none';
    }, { once: true });

    this.slide.style.display = 'block';
    this.slide.style.height = `${this.slide.clientHeight}px`;

    setTimeout(() => {
      this.slide.style.height = '0px';
      if (this.onHide) this.onHide();
    }, 1);

    document.removeEventListener('click', this.outsideClickHandler);
    this.holder.removeEventListener('keydown', this.keyPressHandler);
  }

  clickHandler(e) {
    e.preventDefault();

    if (this.isOpened) {
      this.hideSlide();
    } else {
      this.showSlide();
    }
  }

  outsideClick(e) {
    if (this.isOpened && e.target.closest(this.holder.className)) {
      this.hideSlide();
    }
  }

  keyPress(e) {
    const lastFocusableElement = this.focusableContent[this.focusableContent.length - 1];

    switch (e.key) {
      case 'Escape':
        if (this.holder.querySelector('[aria-expanded="true"]')) {
          e.stopPropagation();
        }

        this.hideSlide();
        document.removeEventListener('keydown', this.keyPressHandler);
        break;

      case 'Tab':
        if (e.shiftKey) {
          if (document.activeElement === this.opener) {
            if (this.isOpened) this.hideSlide();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            if (this.isOpened) this.hideSlide();
          }
        }
        break;
    }
  }

  keyPressHandler(e) {
    switch (e.key) {
      case ' ':
        e.preventDefault();

        if (this.isOpened) {
          this.hideSlide();
        } else {
          this.showSlide();
        }
    }
  }

  destroy() {
    this.opener.removeAttribute('aria-expanded');
    this.opener.removeAttribute('aria-haspopup');
    this.slide.style.height = '';
    this.slide.style.display = '';
    this.slide.style.transition = '';
    this.slide.style.overflow = '';
    this.opener.removeEventListener('click', this.handleClick);
    this.opener.removeEventListener('keyup', this.handleKeyPress);
    this.holder.removeEventListener('keydown', this.keyPressHandler);
  }
}
