import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin);

export default function initDrawIcon() {
  const svgPath = document.querySelectorAll('.offer-card svg path');

  if (svgPath.length === 0) return;

  svgPath.forEach((path, i) => {
    gsap.timeline({ repeat: -1, yoyo: true, delay: i * 0.75 }).fromTo(path, { drawSVG: '0%' }, { duration: 3, drawSVG: '100%', ease: 'expo.inOut' });
  });
}
