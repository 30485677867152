import ready, { HTML } from 'Utils';
import initAccessibilityNav from './components/AccessibilityNav';
import initCollapsible from './components/Collapsible';
import initBurgerMenu from './components/BurgerMenu';
import initVimeoVideo from './components/vimeoVideo';
import initAccordion from './components/accordion';
import initSlickSlider from './components/slickSlider';
import initCustomSelect from './components/customSelect';
import initLoadMore from './components/loadMore';
import initFancybox from './components/fancybox';
import initDrawIcon from './components/drawIcon';
import initFilter from './components/filter';
import initLinesAnimation from './components/linesAnimation';
import initSmoothScroll from './components/smoothScroll';
import initParallax from './components/parallax';
import initChart from './components/chart';
import initInViewport from './components/inViewport';
import initExpandCard from './components/expandCard';
import initPopup from './components/popup';
import initAgreement from './components/agreement';
import initScrollContent from './components/scrollContent';
import initScrollLink from './components/scrollLink';

ready(() => {
  HTML.classList.add('is-loaded');
  initAccessibilityNav();
  initCollapsible();
  initBurgerMenu();
  initVimeoVideo();
  initAccordion();
  initSlickSlider();
  initCustomSelect();
  initLoadMore();
  initFancybox();
  initFilter();
  initDrawIcon();
  initLinesAnimation();
  initSmoothScroll();
  initParallax();
  initChart();
  initInViewport();
  initExpandCard();
  initPopup();
  initAgreement();
  initScrollContent();
  initScrollLink();
});

// jQuery document ready
// jQuery(function() {
//   // init functions
// });

// vanilla document ready
// document.addEventListener('DOMContentLoaded', function () {
//   // do something here ...
// }, false);
