import gsap from 'gsap';

export default function initLinesAnimation() {
  const holders = document.querySelectorAll('.lines-holder');

  holders.forEach((holder) => {
    const block = holder.querySelector('.lines-animation');
    const lines1 = block.querySelectorAll('.lines-1 path');
    const lines2 = block.querySelectorAll('.lines-2 path');
    const completeClass = 'animation-complete';

    const reversedLines1 = Array.from(lines1).reverse();
    const reversedLines2 = Array.from(lines2).reverse();

    const tl = gsap.timeline();

    reversedLines1.forEach((path, index) => {
      tl.set(path, { scaleY: 0, transformOrigin: 'top' }, 0).to(
        path,
        {
          scaleY: 1,
          duration: 1,
          delay: index * 0.02,
        },
        0
      );
    });

    reversedLines2.forEach((path, index) => {
      tl.set(path, { scaleY: 0, transformOrigin: 'top' }, 0).to(
        path,
        {
          scaleY: 1,
          duration: 1,
          delay: index * 0.02,
        },
        0
      );
    });

    tl.add(() => {
      const remainingLines1 = Array.from(lines1).filter((_, index) => [1, 3, 5, 7, 9, 11, 12, 14, 16, 18, 20, 22, 24].includes(index));
      const remainingLines2 = Array.from(lines2).filter((_, index) => [0, 2, 4, 6, 8, 10, 13, 15, 17, 19, 21, 23, 25].includes(index));

      gsap.set(remainingLines1, { opacity: 0 });
      gsap.set(remainingLines2, { opacity: 0 });

      const visibleLines1 = Array.from(lines1).filter((line) => !remainingLines1.includes(line));
      const middleIndex = Math.floor(visibleLines1.length / 2);
      const topGroup1 = visibleLines1.slice(0, middleIndex);
      const bottomGroup1 = visibleLines1.slice(middleIndex);

      const visibleLines2 = Array.from(lines2).filter((line) => !remainingLines2.includes(line));
      const middleIndex2 = Math.floor(visibleLines2.length / 2);
      const topGroup2 = visibleLines2.slice(0, middleIndex2);
      const bottomGroup2 = visibleLines2.slice(middleIndex2);

      topGroup1.forEach((path, index) => {
        tl.set(path, { transformOrigin: 'bottom' }, 0)
          .to(
            path,
            {
              scaleY: 0.4,
              scaleX: 5 - index * (2 / (topGroup1.length - 1)),
              duration: 0.5,
            },
            'lines-1-shrink'
          )
          .to(
            path,
            {
              scaleY: 0.8,
              duration: 0.5,
            },
            'lines-1-grow'
          )
          .to(
            path,
            {
              scaleY: 0.2,
              duration: 0.7,
              transformOrigin: 'top',
            },
            'lines-1-shrink-up'
          );
      });

      bottomGroup1.forEach((path, index) => {
        tl.set(path, { transformOrigin: 'top' }, 0)
          .to(
            path,
            {
              scaleY: 0.4,
              scaleX: 3 + index * (2 / (bottomGroup1.length - 1)),
              stroke: '#1B96A6',
              duration: 0.5,
            },
            'lines-1-shrink'
          )
          .to(
            path,
            {
              scaleY: 1,
              duration: 0.8,
              transformOrigin: 'bottom',
              onStart: () => {
                holder.classList.add(completeClass);
              }
            },
            'lines-1-shrink-up'
          );
      });

      topGroup2.forEach((path, index) => {
        tl.set(path, { transformOrigin: 'top' }, 0)
          .to(
            path,
            {
              scaleY: 0.7,
              duration: 0.5,
              stroke: '#1B96A6',
            },
            'lines-1-shrink'
          )
          .to(
            path,
            {
              scaleY: 1,
              duration: 0.7,
              transformOrigin: 'bottom',
            },
            'lines-1-shrink-up'
          )
          .to(
            path,
            {
              scaleY: 0.7,
              duration: 1,
              transformOrigin: 'top',
            },
            'lines-2-up-final'
          );
      });

      bottomGroup2.forEach((path, index) => {
        tl.set(path, { transformOrigin: 'bottom' }, 0)
          .to(
            path,
            {
              scaleY: 0.7,
              stroke: '#1B96A6',
              duration: 0.5,
              delay: 0.5,
            },
            'lines-1-shrink'
          )
          .to(
            path,
            {
              scaleY: 0.8,
              duration: 0.5,
              delay: 0.5,
            },
            'lines-1-grow'
          )
          .to(
            path,
            {
              scaleY: 0.9,
              duration: 0.5,
              delay: 0.5,
              transformOrigin: 'top',
            },
            'lines-1-shrink-up'
          )
          .to(
            path,
            {
              scaleY: 0.2,
              duration: 1,
              delay: 0.8,
              transformOrigin: 'top',
            },
            'lines-2-up-final'
          );
      });
    });
  });
}
