import '../plugins/SmoothScrollPlugin';
import gsap from 'gsap';

export default function initSmoothScroll() {
  const mm = gsap.matchMedia();

  new SmoothScroll({
    anchorLinks: '.scroll-btn',
    extraOffset: 0,
    wheelBehavior: 'none',
  });

  mm.add('(min-width: 768px)', () => {
    new SmoothScroll({
      anchorLinks: '.sidebar-list a',
      extraOffset: 24,
      wheelBehavior: 'none',
    });
  });

  mm.add('(max-width: 768px)', () => {
    new SmoothScroll({
      anchorLinks: '.sidebar-list a',
      extraOffset: 78,
      wheelBehavior: 'none',
    });
  });
}
