import BurgerMenu from '../plugins/BurgerMenuPlugin';

export default function initBurgerMenu() {
  // function takes 1 argument - breakpoint (default value is '1024px')
  // to set custom breakpoint call it like new BurgerMenu('768px'); It activates menu from 0 to 767ox screens width
  const menuOpener = document.querySelector('.menu-opener');

  if (!menuOpener) return;
  
  const burgerMenu = new BurgerMenu('768px');

  document.body.addEventListener('onShow', () => {
    window.scrollTo({
      top: 0,
    });
  });
}
