import * as d3 from 'd3';

export default function initChart() {
  const chartBlocks = document.querySelectorAll('.chart');
  const clrNavy = '#0A3158';

  function isNotMobile() {
    return window.matchMedia('(min-width: 768px)').matches;
  }

  function renderChart() {
    chartBlocks.forEach((chartBlock) => {
      const isStatic = chartBlock.classList.contains('chart--static');
      const chartView = chartBlock.querySelector('.chart__view');
      const chartLabelList = chartBlock.querySelector('.chart__label-list');
      const { file, keys: dataKeys, colors: dataColors } = chartBlock.dataset;
      const labels = dataKeys.split(',');
      const colors = dataColors.split(',');

      d3.select(chartView).select('svg').remove();
      chartLabelList.innerHTML = '';

      const margin = isStatic ? { top: 10, right: 60, bottom: 34, left: isNotMobile() ? 75 : 95 } : { top: 10, right: 34, bottom: 34, left: isNotMobile() ? 75 : 95 };
      const chartWidth = 800;
      const chartHeight = 400;
      const width = chartWidth - margin.left - margin.right;
      const height = chartHeight - margin.top - margin.bottom;

      const svg = d3
        .select(chartView)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const x = d3.scaleTime().range([0, width]);
      const y = d3.scaleLinear().range([height, 0]);

      d3.csv(file)
        .then((data) => {
          const keys = data.columns.filter((key) => key !== 'date');

          data.forEach((d) => {
            d.date = new Date(d.date);
            keys.forEach((key) => {
              d[key] = +d[key];
            });
          });

          const dateExtent = d3.extent(data, (d) => d.date);
          x.domain(dateExtent);
          const maxValue = d3.max(data, (d) => d3.max(keys, (key) => d[key]));
          let yMax = 0;

          if (isStatic && isNotMobile()) {
            yMax = Math.ceil(maxValue / 50) * 50;
          } else {
            yMax = Math.ceil(maxValue / 100) * 100;
          }

          y.domain([0, yMax]).nice();

          const line = d3
            .line()
            .x((d) => x(d.date))
            .y((d) => y(d.value));

          keys.forEach((key, index) => {
            svg
              .append('path')
              .datum(data.map((d) => ({ date: d.date, value: d[key] })))
              .attr('fill', 'none')
              .attr('stroke', colors[index])
              .attr('stroke-width', index === 0 ? '1.5' : '1')
              .attr('class', 'line')
              .attr('d', line);

            const li = document.createElement('li');
            li.textContent = labels[index];
            li.setAttribute('style', `--color: ${colors[index]}`);
            chartLabelList.appendChild(li);
          });

          // X axis
          svg.append('line').attr('x1', 0).attr('x2', width).attr('y1', height).attr('y2', height).attr('stroke', clrNavy).attr('stroke-width', 1);

          svg
            .append('line')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', height)
            .attr('y2', height + 10)
            .attr('stroke', clrNavy)
            .attr('stroke-width', 1);

          svg
            .append('line')
            .attr('x1', width)
            .attr('x2', width)
            .attr('y1', height)
            .attr('y2', height + 10)
            .attr('stroke', clrNavy)
            .attr('stroke-width', 1);

          // Y axis
          const yIntervals = isStatic ? 50 : 200;
          const yTicks = d3.range(0, yMax + 1, yIntervals);
          const maxYLabel = d3.max(yTicks);

          svg.append('line').attr('x1', 0).attr('x2', 0).attr('y1', y(maxYLabel)).attr('y2', height).attr('stroke', clrNavy).attr('stroke-width', 1);

          yTicks.forEach((value) => {
            svg.append('line').attr('x1', 0).attr('x2', -10).attr('y1', y(value)).attr('y2', y(value)).attr('stroke', clrNavy).attr('stroke-width', 1);

            svg
              .append('text')
              .attr('x', -24)
              .attr('y', y(value))
              .attr('text-anchor', 'end')
              .attr('font-size', isNotMobile() ? '14px' : '20px')
              .attr('fill', clrNavy)
              .text(`${value}%`)
              .attr('transform', `translate(0, ${isNotMobile() ? 6 : 9})`);
          });

          const dateStart = isStatic ? d3.timeFormat('%b %y')(dateExtent[0]) : d3.timeFormat('%Y')(dateExtent[0]);
          const dateEnd = isStatic ? d3.timeFormat('%b %y')(dateExtent[1]) : d3.timeFormat('%Y')(dateExtent[1]);

          // X labels
          svg
            .append('text')
            .attr('x', 0)
            .attr('y', height + margin.bottom - 2)
            .attr('text-anchor', 'middle')
            .attr('font-size', isNotMobile() ? '14px' : '20px')
            .attr('fill', clrNavy)
            .text(dateStart);

          svg
            .append('text')
            .attr('x', width)
            .attr('y', height + margin.bottom - 2)
            .attr('text-anchor', 'middle')
            .attr('font-size', isNotMobile() ? '14px' : '20px')
            .attr('fill', clrNavy)
            .text(dateEnd);

          if (isStatic && isNotMobile()) {
            keys.forEach((key, index) => {
              const lastValue = data[data.length - 1][key];
              svg
                .append('text')
                .attr('x', width + 24)
                .attr('y', y(lastValue))
                .attr('text-anchor', 'start')
                .attr('font-size', isNotMobile() ? '14px' : '20px')
                .attr('fill', colors[index])
                .text(lastValue)
                .attr('transform', `translate(0, ${isNotMobile() ? 6 : 9})`);
            });
          }
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    });
  }

  window.addEventListener('resize', renderChart);

  renderChart();
}
