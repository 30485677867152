import Player from '@vimeo/player';

export default function initVimeoVideo() {
  const videoHolder = document.querySelectorAll('[data-video-id]');

  videoHolder.forEach((holder) => {
    const video = holder.querySelector('.media__content');
    const btn = holder.querySelector('.media__btn');
    const videoId = holder.dataset.videoId;
    const activeClass = 'media--play';

    let player = new Player(video, {
      id: videoId,
      controls: false,
    });

    btn.addEventListener('click', function () {
      holder.classList.add(activeClass);
      
      player
        .destroy()
        .then(() => {
          player = new Player(video, {
            id: videoId,
            controls: true,
          });


          player
            .play()
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  });
}
