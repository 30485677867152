import Accordion from 'accordion-js';

export default function initAccordion() {
  const accordion = document.querySelectorAll('.accordion');

  accordion.forEach((acc) => {
    new Accordion(acc, {
      duration: 300,
      openOnInit: [0]
    });
  });
}