export default function initPopup() {
  jQuery('.info-popup').each(function () {
    const self = jQuery(this);
    const body = jQuery('body');
    const openBtn = self.find('.info-popup__open-btn');
    const closeBtn = self.find('.info-popup__close-btn');
    const content = self.find('.info-popup__content');
    const activeClass = 'active';
    const bodyActiveClass = 'popup-active';
    let isOpen = false;

    openBtn.on('click', openPopup);
    closeBtn.on('click', closePopup);
    jQuery(document).on('click', closeOutside);

    function openPopup() {
      if (isOpen) {
        closePopup();
      } else {
        self.addClass(activeClass);
        body.addClass(bodyActiveClass);
        openBtn.prop('ariaExpanded', true);
        isOpen = true;
      }
    }

    function closePopup() {
      self.removeClass(activeClass);
      body.removeClass(bodyActiveClass);
      openBtn.prop('aria-expanded', false);
      isOpen = false;
    }

    function closeOutside(event) {
      if (!jQuery(event.target).closest(openBtn).length && !jQuery(event.target).closest(content).length) {
        if (isOpen) {
          closePopup();
        }
      }
    }
  });
}
