import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initParallax() {
  const blocks = document.querySelectorAll('.parallax');
  const mm = gsap.matchMedia();

  if (!blocks.length) return;

  mm.add('(min-width: 768px)', () => {
    blocks.forEach((block) => {
      const content = block.querySelector('.parallax__content');

      gsap.timeline({
        scrollTrigger: {
          trigger: content,
          start: 'bottom bottom',
          scrub: true,
          pin: true,
          end: () => {
            const blockHeight = block.offsetHeight;
            const contentHeight = content.offsetHeight;
            const diffHeight = blockHeight - contentHeight;

            return `bottom+=${diffHeight} bottom`;
          },
        },
      });
    });
  });

  mm.add('(max-width: 768px)', () => {
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  });
}
