export default function initFilter() {
  jQuery('.filter').each(function () {
    const self = jQuery(this);
    const select = self.find('select');
    const loadMore = self.find('.load-more-holder');
    const all = 'all';
    let selectedValue = all;
    const baseDuration = 300;

    select.on('change', handleChange);
    loadMore.on('ContentLoader/loaded', handleLoadMore);

    function handleLoadMore() {
      handleFilter(selectedValue, 0);
    }

    function handleChange() {
      const value = jQuery(this).val();

      if (value !== selectedValue) {
        handleFilter(value);

        selectedValue = value;
      }
    }

    function handleFilter(value, duration = baseDuration) {
      const cards = self.find('[data-filter]');

      if (value === all) {
        cards.fadeIn(duration);
      } else {
        cards.hide();

        cards.each(function () {
          const card = jQuery(this);
          const dataDepartment = card.data('filter');

          if (dataDepartment === value) {
            card.fadeIn(duration);
          }
        });
      }
    }
  });
}
