import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initScrollLink() {
  const links = document.querySelectorAll('.sidebar-list a');
  const sections = document.querySelectorAll('.section--21');
  const activeClass = 'anchor-active';

  if (!links.length || !sections.length) return;

  sections.forEach((section, index) => {
    ScrollTrigger.create({
      trigger: section,
      start: 'top center',
      end: 'bottom center',
      onEnter: () => setActiveLink(index),
      onEnterBack: () => setActiveLink(index),
      onLeave: () => removeActiveLink(),
      onLeaveBack: () => removeActiveLink(),
    });
  });

  function setActiveLink(index) {
    links.forEach((link) => link.classList.remove(activeClass));
    links[index].classList.add(activeClass);
  }

  function removeActiveLink() {
    links.forEach((link) => link.classList.remove(activeClass));
  }
}
