export default function initAgreement() {
  const body = document.body;
  const block = document.querySelector('.agreement');

  if (!block) return;

  const btn = block.querySelector('.btn');
  const activeClass = 'show-agreement';

  function setCookie(name, value, days) {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  btn.addEventListener('click', function () {
    setCookie('agreement', true, 30);
    body.classList.remove(activeClass);
  });

  checkConsent();

  function getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function checkConsent() {
    if (!getCookie('agreement')) {
      body.classList.add(activeClass);
    }
  }
}
