import '../plugins/SlickSliderPlugin';

export default function initSlickSlider() {
  jQuery('.testimonials__slider').slick({
    slidesToScroll: 1,
    rows: 0,
    prevArrow: '.testimonials .slick-arrow--prev',
    nextArrow: '.testimonials .slick-arrow--next',
    fade: true,
    dots: true,
  });

  jQuery('.info-slider').slick({
    slidesToScroll: 1,
    rows: 0,
    prevArrow: '.info-controls .slick-arrow--prev',
    nextArrow: '.info-controls .slick-arrow--next',
    focusOnSelect: true,
    infinite: false,
    dots: true,
  });

  jQuery('.awards-slider').slick({
    slidesToScroll: 1,
    rows: 0,
    prevArrow: '.awards-controls .slick-arrow--prev',
    nextArrow: '.awards-controls .slick-arrow--next',
    fade: true,
    infinite: false,
    dots: true,
    swipe: false,
  });

  jQuery('.approach-slider').slick({
    slidesToScroll: 1,
    slidesToShow: 3,
    rows: 0,
    prevArrow: '.approach-controls .slick-arrow--prev',
    nextArrow: '.approach-controls .slick-arrow--next',
    focusOnSelect: true,
    infinite: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  });
}
