import Collapsible from '../plugins/CollapsiblePlugin';

export default function initCollapsible() {
  jQuery('.submenu-opener').on('click', function (e) {
    e.preventDefault();
  });

  document.querySelectorAll('.menubar li').forEach((item) => {
    if (item.querySelector(':scope > ul') || item.querySelector(':scope > div')) {
      const openClose = new Collapsible({
        holder: item,
        opener: '> .submenu-opener',
        slide: '> .submenu-content',
        breakpoint: '768px', // '0' is default value
        onShow: () => {
          setTimeout(() => {
            openClose.holder.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500);
        },
      });
    }
  });
}
