import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initScrollContent() {
  const block = document.querySelector('.disclaimer');

  if (!block) return;

  const heroContent = block.querySelector('.animation-section__col--left');
  const mm = gsap.matchMedia();

  mm.add('(max-width: 768px)', () => {
    ScrollTrigger.create({
      trigger: heroContent,
      start: 'top top',
      end: 'bottom top',
      pinSpacing: false,
      pin: heroContent,
      scrub: true,
      pinType: 'fixed',
    });
  });

  mm.add('(min-width: 768px)', () => {
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  });
}
